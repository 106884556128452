import React, { useMemo } from "react";

export const Pagination = ({
    currentPage,
    itemsPerPage,
    totalItems,
    navigateToPage,
}) => {
    const pageButtons = useMemo(() => {
        const pages = Math.ceil(totalItems / itemsPerPage);
        const visiblePages = 8;

        const pageButtons = [];
        let start = currentPage - Math.floor(visiblePages / 2);
        let end = currentPage + Math.floor(visiblePages / 2);

        if (start < 0) {
            start = 0;
            end = visiblePages;
        }

        if (end > pages) {
            end = pages;
            start = pages - visiblePages;
        }

        if (start < 0) {
            start = 0;
        }

        // Add "Previous" button
        if (currentPage > 1) {
            pageButtons.push(
                <li className="page-item" key="prev">
                    <a
                        href={`#page-${currentPage - 1}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            navigateToPage(currentPage - 1);
                        }}
                    >
                        &laquo;
                    </a>
                </li>,
            );
        }

        // Add ellipses if there are pages before the start of visible pages
        if (start > 0) {
            pageButtons.push(
                <li className="page-item" key="start-ellipsis">
                    <span>...</span>
                </li>,
            );
        }

        for (let i = start; i < end; i++) {
            pageButtons.push(
                <li
                    className={`page-item${currentPage === i + 1 ? " active" : ""}`}
                    key={i + 1}
                >
                    <a
                        href={`#page-${i + 1}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            navigateToPage(i + 1);
                        }}
                    >
                        {i + 1}
                    </a>
                </li>,
            );
        }

        // Add ellipses if there are pages after the end of visible pages
        if (end < pages) {
            pageButtons.push(
                <li className="page-item" key="end-ellipsis">
                    <span>...</span>
                </li>,
            );
        }

        // Add "Next" button
        if (currentPage < pages) {
            pageButtons.push(
                <li className="page-item" key="next">
                    <a
                        href={`#page-${currentPage + 1}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            navigateToPage(currentPage + 1);
                        }}
                    >
                        &raquo;
                    </a>
                </li>,
            );
        }

        return pageButtons;
    }, [currentPage, itemsPerPage, totalItems, navigateToPage]);

    return (
        <ul
            className="pagination"
            style={{ justifyContent: "center", marginTop: "30px" }}
        >
            {pageButtons}
        </ul>
    );
};
