import React, { Component } from "react";
import { withAdminLayout } from "../../layout";
import { withAppContext } from "../../components/withAppContext";
import { adminApiUserlist, adminApiUserUpdate } from "../../util/adminApi";
import { AdminUserTable } from "../../components/AdminUserTable/Index";
import { FormattedMessage } from "react-intl";
import { Input } from "../../components/Form/Input";
import { registerTypes } from "../../components/Register";
import { Select } from "../../components/Form/Select";
import { Pagination } from "../../components/pagination";

const quotaStateType = ["Alle", "grün", "gelb", "rot"];

const registerAll = ["Alle"].concat(registerTypes);

class AdminUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quotaState: quotaStateType[0],
            type: registerAll[0],
            userlist: [],
            currentFilter: {
                fieldname: "contingent",
                direction: "DESC",
            },
            currentPage: 1,
            itemsPerPage: 50,
            totalUsers: 0,
            isLoading: false,
            search: "",
        };
    }

    /**
     * @param {import('react').ChangeEvent<HTMLInputElement>} event
     */
    handleChange = (event) => {
        // update interal state as we type
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    /**
     * @param {import('react').ChangeEvent<HTMLSelectElement>} event
     */
    handleChangeMultiple = (event) => {
        // update interal state as we type
        let value = Array.from(
            event.target.selectedOptions,
            (option) => option.value,
        );
        this.setState({
            [event.target.name]: value,
        });
    };

    /**
     * @param {import('react').FormEvent} event
     */
    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({ isLoading: true });

        const users = await adminApiUserlist(
            this.state.itemsPerPage,
            this.state.currentPage,
            // @ts-ignore
            [this.state.currentFilter],
            this.state.search,
            this.state.type,
            this.state.quotaState,
        );
        this.setState({ userlist: users.result, isLoading: false });
    };

    /**
     * @param {import('react').ChangeEvent<HTMLInputElement>} event
     */
    handleUpdate = (event) => {
        switch (event.target.name) {
            default:
                this.setState({
                    [event.target.name]: event.target.value,
                });
        }
    };

    fetchUserList = (currentPage) => {
        return adminApiUserlist(
            this.state.itemsPerPage,
            currentPage,
            // @ts-ignore
            [this.state.currentFilter],
            this.state.search,
            this.state.type,
            this.state.quotaState,
        );
    };

    async componentDidMount() {
        this.setState({ isLoading: true });
        const response = await this.fetchUserList(1);
        this.setState({
            totalUsers: response.total,
            userlist: response.result,
            isLoading: false,
        });
    }

    updateUser = async (id, updatedUser) => {
        const result = await adminApiUserUpdate(updatedUser);
        this.setState({
            userlist: this.state.userlist.map((user) => {
                return user.id === id ? result : user;
            }),
        });
    };

    /**
     * pageNumber starts at 0
     */
    navigateToPage = async (pageNumber) => {
        const response = await this.fetchUserList(pageNumber);

        this.setState({
            totalUsers: response.total,
            userlist: response.result,
            currentPage: pageNumber,
        });
    };

    calculateIndicesToShow = (currentIndex, totalPages) => {
        // special case where it is not possible to use the regular logic
        if (totalPages < 5) {
            // just show all pages
            return [0, 1, 2, 3];
        } else {
            return [];
        }
    };

    render() {
        const { isLoading, type, quotaState } = this.state;

        return (
            <>
                <h1 className="admin">
                    <FormattedMessage id="dashboard.Admin User" />
                </h1>
                <form
                    method="post"
                    onSubmit={(event) => {
                        this.handleSubmit(event);
                    }}
                >
                    <Input
                        type="text"
                        className="form-input"
                        name="search"
                        placeholder=" "
                        handleChange={this.handleUpdate}
                    ></Input>

                    <div className="form-group">
                        <label className="form-label">
                            <FormattedMessage id="profile.i_am_we_are" />
                        </label>
                        <Select
                            name="type"
                            handleChange={this.handleChangeMultiple}
                            options={registerAll}
                            value={type}
                        />
                        <label className="form-label">
                            <FormattedMessage id="dashboard.Contingent" />
                        </label>
                        <Select
                            name="quotaState"
                            handleChange={this.handleChange}
                            options={quotaStateType}
                            value={quotaState}
                        />
                    </div>
                    <button
                        className={`btn ${isLoading && "loading"}`}
                        disabled={isLoading}
                        type="submit"
                    >
                        Suchen{" "}
                        {/*<FormattedMessage id="loginregister.register" />*/}
                    </button>
                </form>

                <Pagination
                    currentPage={this.state.currentPage}
                    itemsPerPage={this.state.itemsPerPage}
                    totalItems={this.state.totalUsers}
                    navigateToPage={this.navigateToPage}
                />

                {this.state.userlist && (
                    <AdminUserTable
                        users={this.state.userlist}
                        updateUser={this.updateUser}
                        currentFilter={this.state.currentFilter}
                        onFilterChange={(newFilter) => {
                            this.setState(
                                { currentFilter: newFilter },
                                async () => {
                                    const response = await this.fetchUserList(
                                        this.state.currentPage,
                                    );
                                    this.setState({
                                        userlist: response.result,
                                        totalUsers: response.total,
                                    });
                                },
                            );
                        }}
                    />
                )}
                <Pagination
                    currentPage={this.state.currentPage}
                    itemsPerPage={this.state.itemsPerPage}
                    totalItems={this.state.totalUsers}
                    navigateToPage={this.navigateToPage}
                />
            </>
        );
    }
}

const customProps = {
    withAuth: true,
};

export default withAdminLayout(customProps)(withAppContext(AdminUser));
